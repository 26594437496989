<template>
    <div class="action-contact-done">
        <div class="title" v-html="$translate('ACTION_CONTACT_DONE_TITLE')" />
        <div class="body p-b-12">
            <div v-if="body" v-html="body" />
            <div v-if="hasAllInfo" class="profile-info-container m-t-12">
                <div class="profile-info-box">
                    <div class="name">{{ $nameOrNick(messageContent) }}, {{ $options.filters.asAge(birthday) }}</div>
                    <div class="height-region">
                        <span class="cm">{{ height }}cm</span>
                        <span class="bar">ㅣ</span>
                        <span class="region">{{ region.split('(')[0] }}</span>
                    </div>
                </div>
            </div>
            <!-- <div class="footer" v-html="footer" /> -->
            <!-- <div v-if="this.$store.getters.me.reviewable === true" class="review-btn">
                <button @click="onClick()" class="btn-default" v-html="'스토어에 리뷰를 남겨주세요!'" />
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'ActionContactDone',
    props: ['message'],
    computed: {
        messageContent() {
            return this.$mustParse(this.message.content) || {}
        },
        birthday() {
            return this.messageContent.birthday || ''
        },
        height() {
            return this.messageContent.height || ''
        },
        region() {
            return this.messageContent.region || ''
        },
        hasAllInfo() {
            return this.birthday && this.height && this.region
        },
        body() {
            let res

            if (this.isSender) {
                res = this.$translate('ACTION_CONTACT_DONE_BODY_SENDER')
            } else {
                res = this.$translate('ACTION_CONTACT_DONE_BODY_RECEIVER')
            }

            return res.replace(/%s/, this.$nameOrNick(this.messageContent))
        },
        isSender() {
            return this.message.mtype === 'contact-done-sender'
        },
        // footer() {
        //     if (this.isSender) {
        //         return this.$translate('ACTION_CONTACT_DONE_FOOTER_SENDER')
        //     } else {
        //         return this.$translate('ACTION_CONTACT_DONE_FOOTER_RECEIVER')
        //     }
        // },
        chat() {
            return this.$store.getters.chat
        },
        storeUrl() {
            return this.$store.getters.me.os === 'ios'
                ? 'https://apps.apple.com/kr/app/%EB%B0%94%EB%8B%90%EB%9D%BC%EB%B8%8C%EB%A6%BF%EC%A7%80/id1219876826'
                : 'https://play.google.com/store/apps/details?id=com.vanillabridge.app'
        },
    },
    methods: {
        onClick() {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: this.storeUrl,
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.action-contact-done {
    .profile-info-container {
        .profile-info-box {
            padding: 10px 16px;
            margin-bottom: 16px;
            width: 100%;
            height: 60px;
            border-radius: 10px;
            text-align: left;
            justify-content: space-between;
            border: solid 1px $grey-02;

            @include flex;

            .name {
                font-size: 14px;
                color: black;

                @include f-medium;
            }

            .height-region {
                color: $grey-07;
                font-size: 12px;
                letter-spacing: -0.2px;
                line-height: normal;

                @include flex-row;

                .bar {
                    color: $grey-04;
                }
            }
        }
    }

    .footer {
        margin-top: 8px;

        span {
            color: $purple-primary;
        }
    }

    .review-btn {
        text-align: center;

        .btn-default {
            border: none;
            font-size: 13px;
            text-decoration: underline;
            color: #646468;
        }
    }
}
</style>
